import { RouteComponentProps } from "@reach/router";
import { Button, Col, Form, Input, notification, Row, Spin } from "antd";
import { FormComponentProps, FormProps } from "antd/lib/form";
import idx from "idx";
import { GET_PRODUCT_BY_ID, useProductById } from "./_getProductById";
import { useUpdateProduct } from "./_updateProduct";
import {
  GetProductById_product as Product,
  GetProductById_product_images_image as Image,
} from "./types/GetProductById";
import Authorize from "components/authorize";
import DescriptionsFor, { DescriptionItem } from "components/descriptionsFor";
import MainLayout from "components/layouts/main";
import ProductImages from "components/productImages";
import withRouter from "components/withRouter";
import React, { FC, useCallback, useMemo } from "react";
import routes, { ProductEditParams } from "routes";
import { UpdateProductDto } from "types/graphql-global-types";
import { useErrorHandler, useNavigate } from "utils";

type ModelType = UpdateProductDto & { images: Image[] };

type Props = RouteComponentProps<ProductEditParams> &
  FormComponentProps<ModelType>;

const ProductEdit: FC<Props> = ({
  id = "",
  form: { getFieldDecorator, validateFieldsAndScroll },
}) => {
  const { data, loading, completed, error } = useProductById({
    fetchPolicy: "cache-and-network",
    variables: { id },
  });
  const [
    updateProduct,
    { loading: updating, error: updateError },
  ] = useUpdateProduct({
    refetchQueries: [
      "GetProductList",
      { query: GET_PRODUCT_BY_ID, variables: { id } },
    ],
  });

  useErrorHandler(error);
  useErrorHandler(updateError);

  const navigateToProducts = useNavigate(routes.products);

  const items = useMemo<Array<DescriptionItem<Product>>>(
    () => [
      { key: "id", label: "Идентификатор" },
      { key: "name", label: "Наименование" },
      { key: "vendorCode", label: "Артикул" },
      { key: "oldCode", label: "Код (для покупателей)" },
      { key: "unit", label: "Ед. изм." },
      { key: "weight", label: "Вес" },
      { key: "vatRate", label: "Ставка НДС" },
      {
        key: "category",
        label: "Категория",
        render: (_, { category }) => idx(category, x => x.name),
      },
    ],
    []
  );

  const product = useMemo(() => idx(data, x => x.product), [data]);

  const images = useMemo(
    () => (idx(product, x => x.images) || []).map(({ image }) => image) || [],
    [product]
  );

  const handleSubmit = useCallback<NonNullable<FormProps["onSubmit"]>>(
    e => {
      if (e) {
        e.preventDefault();
      }

      validateFieldsAndScroll((validationErros, { images, description }) => {
        if (validationErros) {
          return;
        }

        (async () => {
          const { errors } = await updateProduct({
            variables: {
              input: {
                id: parseInt(id, 10),
                description,
                imageIds: images.map(image => image.id),
              },
            },
          });

          if (!errors) {
            notification.success({
              message: "Успех!",
              description: (
                <>
                  Продукт <strong>{idx(product, x => x.name)}</strong> успешно
                  обновлён.
                </>
              ),
            });

            navigateToProducts();
          }
        })();
      });
    },
    [id, navigateToProducts, product, updateProduct, validateFieldsAndScroll]
  );

  return (
    <Authorize>
      <MainLayout
        title={`Редактирование продукта "${idx(product, x => x.name)}"`}
      >
        <Spin spinning={loading || updating}>
          <Form onSubmit={handleSubmit}>
            <Row gutter={16}>
              <Col span={16}>
                <DescriptionsFor
                  column={2}
                  items={items}
                  dataSource={product}
                />
                <Form.Item label="Описание">
                  {getFieldDecorator<ModelType>("description", {
                    initialValue: idx(product, x => x.description),
                  })(<Input.TextArea />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                {completed && (
                  <Form.Item label="Картинки">
                    {getFieldDecorator<ModelType>("images", {
                      initialValue: images,
                      valuePropName: "images",
                      getValueFromEvent: value => value,
                    })(<ProductImages />)}
                  </Form.Item>
                )}
              </Col>
            </Row>
            <div className="form-actions mb-0">
              <Button
                className="mr-2"
                disabled={updating}
                htmlType="submit"
                icon="save"
                size="large"
                type="primary"
              >
                Сохранить
              </Button>
              <Button
                disabled={updating}
                icon="close"
                size="large"
                type="default"
                onClick={navigateToProducts}
              >
                Отмена
              </Button>
            </div>
          </Form>
        </Spin>
      </MainLayout>
    </Authorize>
  );
};

const ProductEditForm = Form.create<Props>()(ProductEdit);

export default withRouter({ path: routes.productEdit })(ProductEditForm);
