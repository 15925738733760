import { QueryHookOptions, useQuery } from "@apollo/react-hooks";

import GET_PRODUCT_BY_ID from "./getProductById.graphql";
import {
  GetProductById,
  GetProductByIdVariables,
} from "./types/GetProductById";

export function useProductById(
  options?: QueryHookOptions<GetProductById, GetProductByIdVariables>
) {
  const { loading, called, ...result } = useQuery<
    GetProductById,
    GetProductByIdVariables
  >(GET_PRODUCT_BY_ID, options);

  return {
    loading,
    called,
    completed: called && !loading,
    ...result,
  };
}

export { GET_PRODUCT_BY_ID };
