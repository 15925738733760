import { Spin } from "antd";
import React, { ComponentProps, FC, useCallback, useState } from "react";

type Props = ComponentProps<"img">;

const Image: FC<Props> = ({ onLoad, ...props }) => {
  const [loaded, setLoaded] = useState(false);

  const handleLoad = useCallback<NonNullable<Props["onLoad"]>>(
    e => {
      if (typeof onLoad === "function") {
        onLoad(e);
      }

      setLoaded(true);
    },
    [onLoad]
  );

  return (
    <Spin spinning={!loaded}>
      <img onLoad={handleLoad} {...props} />
    </Spin>
  );
};

export default Image;
