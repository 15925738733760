import "react-image-crop/lib/ReactCrop.scss";
import Modal, { ModalProps } from "antd/lib/modal";
import { RcFile, UploadProps } from "antd/lib/upload";
import { useStaticQuery, graphql } from "gatsby";
import React, { ReactElement, ReactNode, useCallback, useState } from "react";
import ReactCrop, { Crop, ReactCropProps } from "react-image-crop";

type BeforeUpload = NonNullable<UploadProps["beforeUpload"]>;
type Resolve<T> = (value?: T | PromiseLike<T>) => void;
type Reject = (reason?: Error) => void;

interface PromiseState<T> {
  resolve: Resolve<T>;
  reject: Reject;
}

interface ImageCropProps {
  aspect?: number;
  children: NonNullable<ReactElement<UploadProps>>;
  hint?: ReactNode;
  maxHeight?: number;
  maxWidth?: number;
  preserveAspect?: boolean;
  rejectOnCancel?: boolean;
  type?: string;
  modalProps?: Pick<
    ModalProps,
    "title" | "okText" | "cancelText" | "style" | "bodyStyle" | "closeIcon"
  >;
  cropProps?: Pick<ReactCropProps, "disabled" | "locked" | "keepSelection">;
}

type Props = ImageCropProps;

const ImageCrop = ({
  aspect,
  children,
  cropProps = {},
  hint,
  maxHeight = 640,
  maxWidth = 960,
  modalProps = {},
  preserveAspect = false,
  rejectOnCancel = true,
  type,
}: Props): ReactElement => {
  const { beforeUpload: childBeforeUpload } = children.props;
  const [originalFile, setOriginalFile] = useState<RcFile>();
  const [promise, setPromise] = useState<PromiseState<RcFile>>(() => ({
    reject: () => void 0,
    resolve: () => void 0,
  }));
  const [src, setSrc] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [crop, setCrop] = useState<Crop>({});
  const [image, setImage] = useState<HTMLImageElement>();

  const {
    background: {
      childImageSharp: { original: background },
    },
  } = useStaticQuery(graphql`
    {
      background: file(relativePath: { eq: "transparent-background.png" }) {
        childImageSharp {
          original {
            src
          }
        }
      }
    }
  `);

  const beforeUpload = useCallback<BeforeUpload>(
    (file: RcFile) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      new Promise<any>((resolve, reject) => {
        setOriginalFile(file);
        setPromise({ resolve, reject });
        const reader = new FileReader();
        reader.onload = ({ target }: ProgressEvent<FileReader>) => {
          if (target) {
            setSrc(target.result as string);
            setIsVisible(true);
          }
        };
        reader.readAsDataURL(file);
      }),
    []
  );

  const renderUpload = useCallback(() => {
    const {
      props: { accept = "image/*", ...props },
      ...Upload
    } = children;

    return {
      ...Upload,
      props: {
        ...props,
        accept,
        beforeUpload,
      },
    };
  }, [beforeUpload, children]);

  const handleImageLoaded = useCallback<
    NonNullable<ReactCropProps["onImageLoaded"]>
  >(
    img => {
      const { width, height } = img;
      const naturalAspect = img.naturalWidth / img.naturalHeight;
      const initialCrop: Crop = { x: 0, y: 0, width, height, unit: "px" };
      if (aspect) {
        initialCrop.aspect = aspect;
        if (naturalAspect > aspect) {
          initialCrop.width = width / naturalAspect;
        } else {
          initialCrop.height = height * naturalAspect;
        }
      } else if (preserveAspect) {
        initialCrop.aspect = naturalAspect;
      }

      setImage(img);
      setCrop(initialCrop);
      return false;
    },
    [aspect, preserveAspect]
  );

  const getCroppedImg = useCallback(
    (
      image: HTMLImageElement,
      { x: cropX, y: cropY, width: cropWidth, height: cropHeight }: Crop,
      type?: string,
      quality?: number
    ) => {
      if (
        cropX === undefined ||
        cropY === undefined ||
        cropWidth === undefined ||
        cropHeight === undefined
      ) {
        throw new Error("Invalid crop object");
      }

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const x = cropX * scaleX;
      const y = cropY * scaleY;
      const width = cropWidth * scaleX;
      const height = cropHeight * scaleY;

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        throw new Error("Can' create canvas context");
      }

      ctx.fillStyle = "rgba(0, 0, 0, 0)";
      ctx.drawImage(image, x, y, width, height, 0, 0, width, height);

      // As a blob
      return new Promise<Blob | null>((resolve, reject) => {
        try {
          canvas.toBlob(resolve, type, quality);
        } catch (err) {
          reject(err);
        }
      });
    },
    []
  );

  const replaceExtension = useCallback((fileName: string, type: string) => {
    const fileNameRegExp = /^(.*)\.[0-1a-z]*$/i;
    const extensionRegExp = /^image\/(.*)$/i;
    if (!fileNameRegExp.test(fileName)) {
      return fileName;
    }

    const typeMatch = extensionRegExp.exec(type);
    if (!typeMatch) {
      return fileName;
    }

    return fileName.replace(fileNameRegExp, `$1.${typeMatch[1]}`);
  }, []);

  const handleOk = useCallback(() => {
    (async () => {
      try {
        if (!originalFile) {
          throw new Error("Invalid component state, original file not loaded.");
        }

        if (!image) {
          throw new Error("Invalid component state, image not loaded.");
        }

        const {
          name: originalFileName,
          type: originalFileType,
          uid,
        } = originalFile;

        const blob = await getCroppedImg(
          image,
          crop,
          type || originalFileType,
          1
        );

        if (!blob) {
          throw new Error("Can't get cropped image blob from file");
        }

        const fileName = type
          ? replaceExtension(originalFileName, type)
          : originalFileName;
        const croppedFile = new File([blob], fileName, {
          type: type || originalFileType,
          lastModified: Date.now(),
        }) as RcFile;
        croppedFile.uid = uid;

        setIsVisible(false);
        setCrop({});

        if (typeof childBeforeUpload === "function") {
          const response = childBeforeUpload(croppedFile, [croppedFile]);
          console.log("TCL: handleOk -> response", response);
          if (typeof response === "boolean") {
            if (response === false) {
              promise.reject();
            } else {
              promise.resolve(croppedFile);
            }
          } else {
            const croppedProcessedFile = ((await response) as any) as RcFile;
            const fileType = Object.prototype.toString.call(
              croppedProcessedFile
            );
            const useProcessedFile =
              fileType === "[object File]" || fileType === "[object Blob]";

            promise.resolve(
              useProcessedFile ? croppedProcessedFile : croppedFile
            );
          }
        } else {
          console.log("TCL: handleOk -> croppedFile", croppedFile);
          promise.resolve(croppedFile);
        }
      } catch (err) {
        promise.reject(err);
      }
    })();
  }, [
    childBeforeUpload,
    crop,
    getCroppedImg,
    image,
    originalFile,
    promise,
    replaceExtension,
    type,
  ]);

  const handleCancel = useCallback(() => {
    setIsVisible(false);
    setCrop({});
    if (rejectOnCancel) {
      promise.reject();
    }
  }, [promise, rejectOnCancel]);

  const handleCropChange = useCallback<ReactCropProps["onChange"]>(
    newCrop => setCrop(newCrop),
    []
  );

  return (
    <>
      {renderUpload()}
      <Modal
        {...modalProps}
        centered={true}
        destroyOnClose={true}
        visible={isVisible}
        width=""
        bodyStyle={{
          alignItems: "center",
          display: "flex",
          height: `calc(${maxHeight}px + 48px)`,
          justifyContent: "center",
          width: `calc(${maxWidth}px + 48px)`,
          overflow: "hidden",
        }}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {hint}
        <ReactCrop
          style={{
            backgroundImage: `url('${background.src}')`,
            backgroundSize: "250px 250px",
          }}
          crop={crop}
          imageStyle={{
            maxWidth: `${maxWidth}px`,
            maxHeight: `${maxHeight}px`,
          }}
          src={src}
          onImageLoaded={handleImageLoaded}
          onChange={handleCropChange}
          {...cropProps}
        ></ReactCrop>
      </Modal>
    </>
  );
};

export default ImageCrop;
