import { MutationHookOptions, useMutation } from "@apollo/react-hooks";

import { UpdateProduct, UpdateProductVariables } from "./types/UpdateProduct";
import UPDATE_PRODUCT from "./updateProduct.graphql";

export function useUpdateProduct(
  options?: MutationHookOptions<UpdateProduct, UpdateProductVariables>
) {
  return useMutation<UpdateProduct, UpdateProductVariables>(
    UPDATE_PRODUCT,
    options
  );
}
