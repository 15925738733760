import { Button, Carousel, Icon, Modal } from "antd";
import { UploadProps } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import { UploadFile } from "antd/lib/upload/interface";
import { useAddProductImage } from "./addProductImage";
import Image from "components/image";
import ImageCrop from "components/imageCrop";
import React, {
  forwardRef,
  RefForwardingComponent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useErrorHandler } from "utils";
import createFileUploadHandler from "utils/fileUploadHandler";

type CustomRequest = NonNullable<UploadProps["customRequest"]>;

interface ProductImage {
  id: number;
  key: string;
  url: string;
}

interface ProductImagesProps {
  id?: string;
  images?: ProductImage[];
  onChange?: (images: ProductImage[]) => void;
}

type Props = ProductImagesProps;

const ProductImages: RefForwardingComponent<Dragger, Props> = (
  { id, images = [], onChange },
  ref
) => {
  const [addProductImage, { error }] = useAddProductImage();

  useErrorHandler(error);

  const customRequest = useMemo(() => {
    const { fileUploadRequestHandler } = createFileUploadHandler(async file => {
      const { errors, data } = await addProductImage({
        variables: { input: { file } },
      });

      if (errors) {
        throw errors[0];
      }

      if (data) {
        const {
          srv: {
            productImage: { uploadFile },
          },
        } = data;

        const { id, key, url } = uploadFile;

        if (typeof onChange === "function") {
          onChange([...images, { id, key, url }]);
        }

        return uploadFile;
      }
    });

    return fileUploadRequestHandler as CustomRequest;
  }, [addProductImage, images, onChange]);

  const defaultFileList = useMemo(
    () =>
      images.map(
        (image): UploadFile => {
          const { id, key, url } = image;
          const dotIndex = key.lastIndexOf(".");
          let type: string;
          if (dotIndex !== -1) {
            type = `image/${key.substr(dotIndex + 1)}`;
          } else {
            type = "image/png";
          }

          return {
            name: key,
            size: 0,
            type,
            uid: `uploaded-image-${id}`,
            url,
            response: image,
          };
        }
      ),
    [images]
  );

  const handleRemove = useCallback<NonNullable<UploadProps["onRemove"]>>(
    ({ response }) => {
      if (typeof onChange === "function" && response) {
        const { id } = response as ProductImage;
        onChange(images.filter(image => image.id !== id));
      }
    },
    [images, onChange]
  );

  const [previewIsVisible, setPreviewIsVisible] = useState(false);
  const previewCarouselRef = useRef<Carousel>(null);

  const handlePreview = useCallback<NonNullable<UploadProps["onPreview"]>>(
    ({ response }) => {
      if (response) {
        const { id }: ProductImage = response;
        const index = images.findIndex(image => image.id === id);
        if (index !== -1 && previewCarouselRef.current) {
          previewCarouselRef.current.goTo(index, true);
        }

        setPreviewIsVisible(true);
      }
    },
    [images]
  );

  const handleNext = useCallback(() => {
    if (previewCarouselRef.current) {
      previewCarouselRef.current.next();
    }
  }, []);

  const handlePrev = useCallback(() => {
    if (previewCarouselRef.current) {
      previewCarouselRef.current.prev();
    }
  }, []);

  const closePreview = useCallback(() => setPreviewIsVisible(false), []);

  return (
    <>
      <ImageCrop
        aspect={1}
        cropProps={{ keepSelection: true }}
        key={`image-crop-${id}`}
        modalProps={{ title: "Выберите область для загрузки" }}
      >
        <Dragger
          customRequest={customRequest}
          defaultFileList={defaultFileList}
          key={`image-upload-${id}`}
          listType="picture-card"
          multiple={false}
          ref={ref}
          onRemove={handleRemove}
          onPreview={handlePreview}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Нажмите или перенесите файл в эту область для загрузки
          </p>
        </Dragger>
      </ImageCrop>
      <Modal
        visible={previewIsVisible}
        closable={false}
        bodyStyle={{ padding: "0" }}
        footer={
          <div style={{ display: "flex" }}>
            <Button icon="arrow-left" onClick={handlePrev} />
            <div style={{ flexGrow: 1 }} />
            <Button icon="arrow-right" onClick={handleNext} />
          </div>
        }
        onCancel={closePreview}
      >
        <Carousel ref={previewCarouselRef}>
          {images.map(({ id, url, key }) => (
            <Image
              key={`image-preview-${id}`}
              style={{ width: "480px", height: "480px" }}
              src={url}
              alt={key}
            />
          ))}
        </Carousel>
      </Modal>
    </>
  );
};

export default forwardRef(ProductImages);
