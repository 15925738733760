import { MutationHookOptions, useMutation } from "@apollo/react-hooks";

import ADD_PRODUCT_IMAGE from "./addProductImage.graphql";
import {
  AddProductImage,
  AddProductImageVariables,
} from "./types/AddProductImage";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useAddProductImage(
  options?: MutationHookOptions<AddProductImage, AddProductImageVariables>
) {
  return useMutation<AddProductImage, AddProductImageVariables>(
    ADD_PRODUCT_IMAGE,
    options
  );
}
